import React, { useState, useEffect, useCallback, useRef } from "react";
// Mui
import {
  Box,
  Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import Typography from "@mui/material/Typography";

// Modules
import Filter from "./Filter";
import Control from "./Control";

import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeGrid, FixedSizeList } from "react-window";

import AutoSizer from "react-virtualized-auto-sizer";

// Global
import OrderCard, {
  OrderCardSkeleton,
} from "src/modules/Orders/OrderCard/OrderCard";

// Modules
import WalletQuickView from "./WalletQuickView";
import AdvancedFilterDialog from "./AdvancedFilterDialog";

// Hooks
import { INIT_ADVANCED_FILTER_DATA, PER_PAGE_ORDERS } from "./constants";

// Services
import OrderService from "src/service/Order";

// LIBS
import InfiniteScroll from "react-infinite-scroll-component";

// Configs
import Sort from "./Sort";

// Socker

import { socket } from "src/socket";

// Timer
let timerId;

function Orders() {
  // Фильтр статуса и сайта
  const [filter, setFilter] = useState({
    site: "all",
    status: "all",
  });
  const [sort, setSort] = useState({
    isOpen: false,
    query: {},
  });

  // Расширенный фильтр - дилоговое окно
  const [advancedFilter, setAdvancedFilter] = useState({
    show: false,
    query: INIT_ADVANCED_FILTER_DATA,
  });
  // Поиск
  const [search, setSearch] = useState("");
  const [hasNextPage, setHasNextPage] = useState(true);

  // Данные
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  // Вычисляемые значения
  const { site, status } = filter;

  const showAdvancedFilter = advancedFilter.show;

  let isSearchFilter = !!search.trim();
  let isMultiFilter = objHasValues(advancedFilter.query);
  let isAdvancedFilterOrSearch = !!(isSearchFilter || isMultiFilter);
  let isSortOpen = sort.isOpen;

  // Хуки
  useEffect(() => {
    socket.on("order_add", addOrder);
    socket.on("order_upd_item", updateOrder);
    return () => {
      socket.off("order_upd_item", updateOrder);
      socket.off("order_add", addOrder);
    };
  }, []);

  const addOrder = useCallback((o) => setOrders((list) => [o, ...list]), []);

  const updateOrder = useCallback((u) => {
    setOrders((list) => {
      return list.map((o) => {
        return o._id == u._id ? Object.assign({}, o, u) : o;
      });
    });
  }, []);

  useEffect(() => {
    if (!isAdvancedFilterOrSearch) {
      requestOrders(true, "main");
      return;
    }

    if (isMultiFilter) {
      if (!showAdvancedFilter) {
        requestOrders(true, "advanced");
      }
    } else {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        requestOrders(true, "advanced");
      }, 2000);
    }
  }, [status, site, search, isMultiFilter, showAdvancedFilter]);

  // Обработчики
  const closeAdvancedFilter = () => {
    setAdvancedFilter((prev) => ({
      ...prev,
      show: false,
    }));
  };

  const openAdvancedFilter = useCallback(() => {
    setAdvancedFilter((prev) => ({
      ...prev,
      show: true,
    }));
  }, []);

  const changeAdvancedFilterQuery = useCallback((data) => {
    setAdvancedFilter((prev) => ({
      ...prev,
      query: data,
    }));
  }, []);

  const clearAdvancedFilterAndSearch = useCallback(() => {
    setSearch("");
    setAdvancedFilter((prev) => ({
      ...prev,
      query: INIT_ADVANCED_FILTER_DATA,
    }));
  }, []);

  const changeSite = useCallback((value) => {
    setFilter((prev) => ({
      ...prev,
      site: value,
    }));
  }, []);

  const changeStatus = useCallback((value) => {
    setFilter((prev) => ({
      ...prev,
      status: value,
    }));
  }, []);

  const openSort = useCallback(() => {
    setSort((prev) => ({
      ...prev,
      isOpen: true,
    }));
  }, []);

  const closeSort = useCallback(() => {
    setSort({
      query: {},
      isOpen: false,
    });
    requestOrders(true, "main");
  }, []);

  const sortSearchHandler = (query) => {
    setSort((prev) => ({
      ...prev,
      query,
    }));
  };

  useEffect(() => {
    if (isSortOpen && Object.keys(sort.query).length) {
      requestOrders(true, "main");
    }
  }, [sort]);

  // API

  const mainRequestApi = (fromStart = false) => {
    console.log("mainRequestApi", fromStart);
    let sortQuery = isSortOpen ? sort.query : undefined;
    return OrderService.getList({
      filter: {
        status,
        site,
      },
      pagination: {
        limit: PER_PAGE_ORDERS,
        skip: fromStart ? 0 : orders.length,
      },
      sort: sortQuery,
    });
  };

  const advancedRequestApi = (fromStart = false) => {
    let mode = isMultiFilter ? "multi" : "search";
    let data = isMultiFilter ? advancedFilter.query : search.trim();
    return OrderService.getSearchList({
      filter: {
        data,
        mode,
      },
      pagination: {
        limit: PER_PAGE_ORDERS,
        skip: fromStart ? 0 : orders.length,
      },
    });
  };

  const requestOrders = async (fromStart = false, endpoint) => {
    console.log("requestOrders", fromStart, endpoint);
    let fetchApi;
    if (endpoint) {
      if (endpoint === "main") {
        fetchApi = mainRequestApi;
      } else if (endpoint === "advanced") {
        fetchApi = advancedRequestApi;
      }
    } else {
      fetchApi = isAdvancedFilterOrSearch ? advancedRequestApi : mainRequestApi;
    }
    if (fromStart) {
      setHasNextPage(true);
    }

    setLoading(true);
    return fetchApi(fromStart)
      .then(({ data }) => {
        if (data.length < PER_PAGE_ORDERS) {
          setHasNextPage(false);
        }
        setOrders((prev) => {
          return fromStart ? data : [...prev, ...data];
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // Calc
  let filtredOrders = isAdvancedFilterOrSearch
    ? orders
    : filterOrder(site, status, [...orders]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <AdvancedFilterDialog
        closeModal={closeAdvancedFilter}
        show={showAdvancedFilter}
        handleClose={closeAdvancedFilter}
        confirmHandler={changeAdvancedFilterQuery}
      />

      {!isAdvancedFilterOrSearch && (
        <Filter
          site={site}
          setSite={changeSite}
          status={status}
          setStatus={changeStatus}
        />
      )}

      {!isSortOpen && (
        <Control
          openAdvancedFilter={openAdvancedFilter}
          search={search}
          setSearch={setSearch}
          openSort={openSort}
          isSortOpen={isSortOpen}
          isMultiFilter={isMultiFilter}
          isAdvancedFilter={isAdvancedFilterOrSearch}
          clearAdvancedFilters={clearAdvancedFilterAndSearch}
        />
      )}
      {isSortOpen && (
        <Sort
          searchHanler={sortSearchHandler}
          closeSort={closeSort}
          isLoading={loading && isSortOpen}
        />
      )}

      {!!filtredOrders.length && (
        <VirtualizedOrders
          orders={filtredOrders}
          hasNextPage={hasNextPage}
          isNextPageLoading={loading}
          loadNextPage={requestOrders}
          isAdvancedFilterOrSearch={isAdvancedFilterOrSearch}
        />
      )}

      {!loading && !filtredOrders.length && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <Typography variant="h6">Заявок не найдено</Typography>
        </Box>
      )}

      {/* <InfiniteScroll
        dataLength={orders.length}
        next={requestOrders}
        hasMore={true}
      >
        <Grid sx={{ mt: 4 }} container spacing={4}>
          {filtredOrders.map((o) => {
            return (
              <Grid key={o._id} item xs={12} sm={6} md={3}>
                <OrderCard
                  visibleArchived={isAdvancedFilterOrSearch}
                  key={o._id}
                  status={o?.status}
                  data={o}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll> */}

      {/* {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )} */}
      <WalletQuickView />
    </Box>
  );
}

const VirtualizedOrders = React.memo(
  ({
    orders,
    hasNextPage,
    isNextPageLoading,
    loadNextPage,
    isAdvancedFilterOrSearch,
  }) => {
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.up("xs"));
    const isSM = useMediaQuery(theme.breakpoints.up("sm"));
    const isMD = useMediaQuery(theme.breakpoints.up("md"));

    let countOrdersInRow = 0;
    if (isMD) {
      countOrdersInRow = 4;
    } else if (isSM) {
      countOrdersInRow = 2;
    } else if (isXS) {
      countOrdersInRow = 1;
    }

    let rowsCount = Math.ceil(orders.length / countOrdersInRow);
    rowsCount = hasNextPage ? rowsCount + 1 : rowsCount;
    // console.log(orders.length / countOrdersInRow, "calc rowsCount");

    const loadMoreItems = (args) => {
      if (isNextPageLoading) {
        return Promise.resolve(); // Возвращаем пустой промис
      }
      return Promise.resolve(loadNextPage());
    };

    // const itemCount = hasNextPage ? rowsCount + 1 : rowsCount;

    const itemCount = 9999;

    const isItemLoaded = (index) => !hasNextPage || index < rowsCount;

    const renderRow = ({ index, style }) => {
      let startIndex = index * countOrdersInRow;
      let endIndex = startIndex + countOrdersInRow;
      let ordersInRow = orders.slice(startIndex, endIndex).filter((o) => o);

      let restCount = countOrdersInRow - ordersInRow.length;

      let fillSkeleton = hasNextPage ? new Array(restCount) : [];

      let resultList = [...ordersInRow, ...fillSkeleton];

      return (
        <div style={style}>
          <Grid sx={{}} container spacing={4}>
            {resultList.map((o, i) => {
              let hasOrder = !!o;

              return (
                <Grid key={o?._id || i} item xs={12} sm={6} md={3}>
                  {hasOrder ? (
                    <OrderCard
                      visibleArchived={isAdvancedFilterOrSearch}
                      key={o._id}
                      status={o?.status}
                      data={o}
                    />
                  ) : (
                    <OrderCardSkeleton key={i} />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </div>
      );
    };

    const itemHeight = 348 + 16;

    return (
      <div
        style={{
          marginTop: "20px",
          borderRadius: "10px",
          overflow: "hidden",
          minHeight: `${itemHeight * 2}px`,
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
              threshold={4}
            >
              {(props) => (
                <FixedSizeList
                  height={itemHeight * 2}
                  itemCount={rowsCount}
                  itemSize={itemHeight}
                  width={width}
                  {...props}
                >
                  {renderRow}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
    );
  }
);
// Methods

// const Cell =
function objHasValues(obj) {
  return !!Object.values(obj).filter((v) => v).length;
}

function filterOrder(sc, status, orders) {
  let result = [...orders];

  if (sc !== "all") {
    result = result.filter((o) => o.sc == sc);
  }

  if (status !== "all") {
    switch (status) {
      case "new":
        result = result.filter((o) => {
          let s = o.status;
          return (s == 101 || s == 102 || s == 103 || s == 104) && !o.archived;
        });
        break;
      case "completed":
        result = result.filter((o) => {
          let s = o.status;
          return s == 105 && !o.archived;
        });
        break;
      case "rejected":
        result = result.filter((o) => {
          let s = o.status;
          return s == 106 && !o.archived;
        });
        break;
      case "check":
        result = result.filter((o) => {
          return o.onCheck;
        });
        break;
      case "archive":
        result = result.filter((o) => {
          return o.archived;
        });
        break;
      case "problem":
        result = result.filter((o) => {
          return o.isProblematic;
        });
        break;
      default:
    }
  } else {
    result = result.filter((o) => {
      return !o.archived;
    });
  }
  return result;
}

export default Orders;
