import { useState, useEffect } from "react";
// Mui
import {
  Box,
  Card,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Components
import PageWrap from "src/components/PageWrap";


// Time
import moment from "moment";

// Services
import OrderAcquiringService from "src/service/OrderAcquiring";



function FeedbackWish() {
  let [list, setList] = useState([]);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    OrderAcquiringService.wishGetAll().then((r) => {
      setList(r.data);
      setLoading(false);
    });
  }, []);

  let listWithId = list.map((c) => Object.assign({}, c, { id: c._id }));



  return (
    <PageWrap title="Пожелания к сервису">
    
      <Card>
        <DataGrid
          isRowSelectable={false}
          columns={columns}
          autoHeight
          loading={loading}
          rows={listWithId}
        //   sortable={false}
          sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
          
        />
      </Card>
    </PageWrap>
  );
}




const columns = [
  {
    flex: 0.1,
    // minWidth: 100,
    field: "ip",
    headerName: "IP",
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: 14 }} variant="subtitle1" noWrap>
          {row.ip}
        </Typography>
      );
    },
  },
  {
    flex: 0.75,
    // minWidth: 120,
    field: "text",
    headerName: "Текст",
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: 14 }} variant="subtitle1" noWrap>
          {row.text}
        </Typography>
      );
    },
  },
  {
    flex: 0.05,
    // minWidth: 80,
    field: "rating",
    headerName: "Рейтинг",
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: 14 }} variant="body1" noWrap>
          {row.rating}
        </Typography>
      );
    },
  },
  
  {
    flex: 0.1,
    // minWidth: 100,
    field: "createdAt",
    headerName: "Создано",
    renderCell: ({ row }) => {
      return (
        <Typography variant="body2" noWrap>
          {moment(row.createdAt).format("DD.MM.YYYY, в HH:mm")}
        </Typography>
      );
    },
  },
];

export default FeedbackWish;
