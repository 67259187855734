import { useState } from "react";
// Mui
import {
  Box,
  Card,
  CardContent,
  Avatar,
  Typography,
  Grid,
  Alert,
  TextField,
} from "@mui/material";

// Config
import ConfigApi from "src/configs/api";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

// ** Utils
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";

// Store
import { observer } from "mobx-react-lite";

function Currency({ data }) {
  let [search, setSearch] = useState("");
  let [showInfo, setShowInfo] = useState(true);

  let directions = [...data.directions].filter((d) => d.enabled);

  directions = search ? getFilteringDirections(directions, search) : directions;

  // console.log(directions, "directions");

  return (
    <>
      {showInfo && (
        <Alert
          onClose={(e) => {
            e.preventDefault();
            setShowInfo(false);
          }}
          sx={{
            mb: 4,
            "& .MuiAlert-message": {
              width: "100%",
            },
          }}
          severity="info"
          componentsProps={{
            width: "100%",
          }}
        >
          Цена "Купили", "Продали", "Курс покупки", "Курс продажи", "Доход"
          считается по цене валют на момент создания заявки если есть данные,
          иначе по текущему.
          <Typography variant="body2" textAlign="end">
            upd 11.09.2023
          </Typography>
        </Alert>
      )}
      <Card sx={{ mb: 3 }}>
        <CardContent sx={{ display: "flex", py: "12px !important" }}>
          <Typography variant="h6">Общее</Typography>
        </CardContent>
      </Card>

      <Card sx={{ mb: 5 }}>
        <CardContent>
          <Box sx={{ mt: 4 }}>
            <CurrencyDetails
              name={data.name}
              countCode={data.code}
              buyOrders={data.orders?.buy}
              sellOrders={data.orders?.sell}
              buyCount={data?.buy?.count}
              buyAmount={data?.buy?.amount}
              sellCount={data?.sell?.count}
              sellAmount={data?.sell?.amount}
              rateBuy={data?.rate?.buy}
              rateSell={data?.rate?.sell}
              income={data?.income}
              profit={data?.profit}
              cashBack={data?.accruals?.cashBack}
              referral={data?.accruals?.referral}
            />
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent
          sx={{
            display: "flex",
            py: "12px !important",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">По валютам</Typography>
          <TextField
            value={search}
            label="Валюта"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
          />
        </CardContent>
      </Card>

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={4}>
          {directions.map((d) => {
            return (
              <Grid item xs={6}>
                <Card sx={{ minHeight: "100%" }}>
                  <CardContent>
                    <CurrencyInfo size="small" name={d.name} image={d.image} />
                    <Box sx={{ mt: 4 }}>
                      <CurrencyDetails
                        name={d.name}
                        countCode={data.code}
                        size="small"
                        buyOrders={d.orders?.buy}
                        sellOrders={d.orders?.sell}
                        buyCount={d?.sell?.count}
                        buyAmount={d?.sell?.amount}
                        sellCount={d?.buy?.count}
                        sellAmount={d?.buy?.amount}
                        rateBuy={d?.rate?.sell}
                        rateSell={d?.rate?.buy}
                        income={d?.income}
                        profit={d?.profit}
                        cashBack={d?.accruals?.cashBack}
                        referral={d?.accruals?.referral}
                        precision={data.precision}
                        // data={d}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}

const CurrencyDetails = observer(function ({
  size = "medium",
  name = "-",
  precision = 2,
  countCode,
  buyOrders,
  sellOrders,
  buyCount,
  buyAmount,
  sellCount,
  sellAmount,
  rateBuy,
  rateSell,
  income,
  profit,
  cashBack,
  referral,
}) {
  return (
    <Grid container spacing={4}>
      <TextInfoStyleWrap size={size} data={{}} gridData={{ xs: 6 }}>
        Заявок покупаю {name}:
        <br />
        <span className="count">{buyOrders}</span>
        {/* <span className="count">{data.orders.buy}</span> */}
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={{}} gridData={{ xs: 6 }}>
        Заявок продаю {name}:
        <br />
        <span className="count">{sellOrders}</span>
        {/* <span className="count">{data.orders.sell}</span> */}
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={{}} gridData={{ xs: 6 }}>
        Купили {name}:
        <br />
        {buyCount && buyAmount ? (
          <>
            <span className="count">
              {new Intl.NumberFormat("ru-RU", {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
              }).format(buyCount)}
            </span>
            &nbsp;
            <span className="code">{countCode}</span>&nbsp;за&nbsp;
            <span className="count">{getPriceCurrentFiat(buyAmount, 0)}</span>
          </>
        ) : (
          <span>-</span>
        )}
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={sellAmount} gridData={{ xs: 6 }}>
        Продали {name}:
        <br />
        {sellCount && sellAmount ? (
          <>
            <span className="count">
              {new Intl.NumberFormat("ru-RU", {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
              }).format(sellCount)}
            </span>
            &nbsp;
            <span className="code">{countCode}</span>&nbsp;за&nbsp;
            <span className="count">{getPriceCurrentFiat(sellAmount, 0)}</span>
          </>
        ) : (
          <span>-</span>
        )}
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={rateBuy} gridData={{ xs: 6 }}>
        Курс покупки {name}:
        <br />
        {rateBuy ? (
          <>
            <span className="count">1</span>&nbsp;
            <span className="code">{countCode}</span>&nbsp;за&nbsp;
            <span className="count">{getPriceCurrentFiat(rateBuy, 0)}</span>
          </>
        ) : (
          <span>-</span>
        )}
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={rateSell} gridData={{ xs: 6 }}>
        Курс продажи {name}:
        <br />
        {rateSell ? (
          <>
            <span className="count">1</span>&nbsp;
            <span className="code">{countCode}</span>&nbsp;за&nbsp;
            <span className="count">{getPriceCurrentFiat(rateSell, 0)}</span>
          </>
        ) : (
          <span>-</span>
        )}
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={income} gridData={{ xs: 6 }}>
        Доход
        <br />
        <span className="count">{getPriceCurrentFiat(income, 0)}</span>
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={profit} gridData={{ xs: 6 }}>
        Прибыль
        <br />
        <span className="count">{getPriceCurrentFiat(profit, 0)}</span>
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={cashBack} gridData={{ xs: 6 }}>
        Кэшбэк
        <br />
        <span className="count">{getPriceCurrentFiat(cashBack, 0)}</span>
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={referral} gridData={{ xs: 6 }}>
        Реф. программа
        <br />
        <span className="count">{getPriceCurrentFiat(referral, 0)}</span>
      </TextInfoStyleWrap>
    </Grid>
  );
});

function TextInfoStyleWrap({ children, size = "medium", gridData = {} }) {
  // if (!data) return;

  let isSmall = size == "small";

  return (
    <Grid item {...gridData}>
      <Box
        sx={{
          fontSize: isSmall ? "11px" : "16px",
          color: (t) => t.palette.text.secondary,
          alignItems: "center",
          "word-break": "break-all",
          "& .code,.count": {
            fontSize: isSmall ? "13px" : "17px",
            color: (t) => t.palette.text.primary,
          },
        }}
      >
        {children}
      </Box>
    </Grid>
  );
}

function CurrencyInfo({ image, name, size = "medium" }) {
  let customAvatarSize = 46;
  let avatarSize = 36;
  let typographyVariant = "h6";

  if (size == "small") {
    customAvatarSize = 36;
    avatarSize = 26;
    typographyVariant = "body1";
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: customAvatarSize, height: customAvatarSize, mr: 3 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + image + ".png"}
          alt={name}
          sx={{ height: avatarSize, width: avatarSize }}
        />
      </CustomAvatar>
      <Typography variant={typographyVariant}>{name}</Typography>
    </Box>
  );
}

function getFilteringDirections(list, text = "") {
  let st = text.trim().toLowerCase();

  let result = [...list];

  return result.filter((d) => {
    return (
      d.name.toLowerCase().trim().includes(st) ||
      d.code.toLowerCase().trim().includes(st)
    );
  });
}

export default observer(Currency);
