import { useState, useEffect } from "react";
//MUi
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
//Services
import CurrencyService from "src/service/Currencies";

// Components
import CurrencySelect from "src/components/CurrencySelect";

import { toast } from "react-hot-toast";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import WalletService from "src/service/Wallet";

import { isObject } from "src/utils/data";

function DialogTransferBalance(props) {
  let { show, closeHandler, takeIsKit, takeId, transferCurrency } = props;

  // States
  const [currencies, setCurrencies] = useState([]);
  const [currencyId, setCurrencyId] = useState("");
  const [isSubmiting, setSubmiting] = useState(false);
  const [kits, setKits] = useState([]);
  const [kitId, setKitId] = useState("");
  const [reqId, setReqId] = useState("");
  const [takeAmount, setTakeAmount] = useState(0);
  const [giveAmount, setGiveAmount] = useState(0);
  const [commission, setCommission] = useState(0);
  const [commissionCurrencyKits, setCommissionCurrencyKits] = useState([]);
  const [selectedCmsKitId, setSelectedCmsKitId] = useState();

  let currency = currencies.find((c) => c._id == currencyId);
  let isCrypto = currency?.isCrypto;

  let kit = kits.find((k) => k._id == kitId);

  let takeCode = transferCurrency?.code;

  let showSelectKits = currency && !!kits.length;
  let showSelectRequisites = showSelectKits && !!kit?.requisites?.length;
  let showAmountFields = isCrypto ? showSelectKits : showSelectRequisites;

  const isEqualCurrency = transferCurrency?._id + "" === currencyId + "";
  const netCurrency = currency?.netCurrency;
  const netCurrencyId = netCurrency?._id;
  const existNetCurrency = isObject(netCurrency);

  useEffect(() => {
    let exs = isEqualCurrency && netCurrencyId;
    if (!exs) return;

    WalletService.getBalance(netCurrencyId).then(({ data }) => {
      let kitsWithBalance = data.filter((k) => k.balance > 0);
      kitsWithBalance = [
        { _id: "", name: "Без комиссии", balance: "-" },
        ...kitsWithBalance,
      ];
      setCommissionCurrencyKits(kitsWithBalance);
      let firstKitId = kitsWithBalance[0]?._id;

      if (firstKitId) {
        setSelectedCmsKitId(firstKitId);
      }
    });
  }, [isEqualCurrency, existNetCurrency, netCurrencyId]);

  // console.log(commissionCurrencyKits, "commissionCurrencyKits");

  useEffect(() => {
    CurrencyService.getList({
      populate: ["wallet", "netCurrency"],
    }).then((r) => {
      setCurrencies(r.data);
    });
  }, []);

  useEffect(() => {
    if (!show) {
      setCommission(0);
      setCommissionCurrencyKits([]);
      setSelectedCmsKitId(undefined);
      return;
    }
    setCommission(0);
    setCommissionCurrencyKits([]);
    setSelectedCmsKitId(undefined);
  }, [show, currencyId]);

  useEffect(() => {
    if (!currencyId) return;
    WalletService.getBalance(currencyId).then((r) => {
      setKits(r.data);
      let firstKit = r.data[0];
      if (firstKit) {
        setKitId(firstKit._id);
        if (!isCrypto) {
          let requisites = firstKit.requisites;
          if (requisites[0]) {
            setReqId(requisites[0]._id);
          }
        }
      }
    });
  }, [currencyId]);

  const handleClose = () => {
    closeHandler();
  };

  const onSubmit = () => {
    setSubmiting(true);
    let queryObj = {
      take: {
        amount: +takeAmount,
        isKit: takeIsKit,
        id: takeId,
      },
      give: {
        amount: +giveAmount,
        isKit: isCrypto,
        id: isCrypto ? kitId : reqId,
      },
    };

    if (isEqualCurrency && commission && selectedCmsKitId) {
      queryObj.commission = {
        amount: +commission,
        kitId: selectedCmsKitId,
      };
    }
    WalletService.balanceTransfer(queryObj)
      .then((r) => {
        toast.success(`Перевод произведен с разницей ${r.data.percent}%`);
        handleClose();
      })
      .finally(() => setSubmiting(false));
  };

  return (
    <Box>
      <Dialog fullWidth maxWidth="xs" open={show} onClose={handleClose}>
        <DialogContent
          sx={{
            px: { xs: 8, sm: 12 },
            py: { xs: 8, sm: 12 },
            position: "relative",
          }}
        >
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{ position: "absolute", right: "1rem", top: "1rem" }}
          >
            <Icon icon="mdi:close" />
          </IconButton>
          <Box sx={{ mb: 4, textAlign: "center" }}>
            <Typography variant="h6" sx={{ mb: 3, lineHeight: "2rem" }}>
              Перевод средств
            </Typography>
          </Box>
          <Box>
            <CurrencySelect
              containerStyle={{ mt: 4 }}
              label="Валюта"
              placeholder="Выберите валюту"
              currencies={currencies}
              value={currencyId}
              setValue={(v) => {
                setCurrencyId(v);
              }}
            />
            {showSelectKits && (
              <SelectKit
                kits={kits}
                kitId={kitId}
                setKitId={setKitId}
                selectCurrency={currency}
              />
            )}

            {showSelectRequisites && (
              <SelectReq
                reqId={reqId}
                setReqId={setReqId}
                reqs={kit.requisites}
                selectCurrency={currency}
              />
            )}

            {showAmountFields && (
              <AmountFields
                takeAmount={takeAmount}
                setTakeAmount={setTakeAmount}
                takeCode={takeCode}
                giveAmount={giveAmount}
                setGiveAmount={setGiveAmount}
                giveCode={currency?.code}
              />
            )}

            {isEqualCurrency && existNetCurrency && (
              <>
                <SelectKit
                  kits={commissionCurrencyKits}
                  kitId={selectedCmsKitId}
                  setKitId={setSelectedCmsKitId}
                  selectCurrency={netCurrency}
                />
                {selectedCmsKitId && (
                  <TextField
                    fullWidth
                    sx={{ mt: 4 }}
                    value={commission}
                    onChange={(e) => setCommission(e.target.value)}
                    type="number"
                    label={`Комиссия ${netCurrency?.code}`}
                  />
                )}
              </>
            )}

            {showAmountFields && (
              <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                  startIcon={
                    isSubmiting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isSubmiting}
                  variant="contained"
                  sx={{ mr: 2 }}
                  type="submit"
                  onClick={onSubmit}
                >
                  Обменять
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Отмена
                </Button>
              </DialogActions>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

function SelectKit(props) {
  let { kits, kitId, setKitId, selectCurrency } = props;

  let label = "Комплект " + selectCurrency.name;

  return (
    <FormControl fullWidth sx={{ mt: 4 }}>
      <InputLabel id="transfer_balance_select_kit">{label}</InputLabel>
      <Select
        value={kitId}
        onChange={(e) => setKitId(e.target.value)}
        fullWidth
        label={label}
        labelId="transfer_balance_select_kit"
        renderValue={(value) => {
          let k = kits.find((k) => k._id == value);
          if (!k) return "";
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1">{k.name}</Typography>
              <Typography
                variant="body2"
                color="secondary"
              >{`${k.balance} ${selectCurrency.code}`}</Typography>
            </Box>
          );
        }}
      >
        {kits.map((k) => {
          return (
            <MenuItem
              key={k._id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              value={k._id}
            >
              <Typography variant="body1">{k.name}</Typography>
              <Typography
                variant="body2"
                color="secondary"
              >{`${k.balance} ${selectCurrency.code}`}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function SelectReq(props) {
  let { reqId, setReqId, reqs, selectCurrency } = props;
  return (
    <FormControl fullWidth sx={{ mt: 4 }}>
      <InputLabel id="transfer_balance_select_req">Реквизит</InputLabel>
      <Select
        value={reqId}
        onChange={(e) => setReqId(e.target.value)}
        fullWidth
        label="Реквизит"
        labelId="transfer_balance_select_req"
        renderValue={(value) => {
          let r = reqs.find((r) => r._id == value);
          if (!r) return "";
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1">{r.name}</Typography>
              <Typography
                variant="body2"
                color="secondary"
              >{`${r.balance} ${selectCurrency.code}`}</Typography>
            </Box>
          );
        }}
      >
        {reqs.map((r) => {
          return (
            <MenuItem
              key={r._id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              value={r._id}
            >
              <Typography variant="body1">{r.name}</Typography>
              <Typography
                variant="body2"
                color="secondary"
              >{`${r.balance} ${selectCurrency.code}`}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function AmountFields(props) {
  let {
    takeAmount,
    setTakeAmount,
    takeCode,
    giveAmount,
    setGiveAmount,
    giveCode,
  } = props;
  return (
    <Grid sx={{ mt: 0 }} container spacing={4}>
      <Grid item xs={6}>
        <TextField
          value={takeAmount}
          onChange={(e) => setTakeAmount(e.target.value)}
          type="number"
          label={`Отдаю ${takeCode}`}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={giveAmount}
          onChange={(e) => setGiveAmount(e.target.value)}
          type="number"
          label={`Получаю ${giveCode}`}
        />
      </Grid>
    </Grid>
  );
}

export default DialogTransferBalance;
