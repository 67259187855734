import { useState, useEffect } from "react";
// Modules
import PageWrap from "src/components/PageWrap";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import { Typography } from "@mui/material";

import moment from "moment";

// Services
import HistoryService from "src/service/History";

// ** Icon
import Icon from "src/@core/components/icon";

// Modules
import Action from "./Modules/Action";
import ChangeBalance from "./Modules/ChangeBalance";
import { toast } from "react-hot-toast";

const columns = [
  { id: "order", label: "Процент", minWidth: 50 },
  { id: "sum", label: "Сумма", minWidth: 50 },
  {
    id: "action1",
    label: "Отдаю",
  },
  {
    id: "kit1",
    label: "Комплект",
  },
  {
    id: "requisite1",
    label: "Реквизит",
  },
  {
    id: "action2",
    label: "Получаю",
  },
  {
    id: "kit2",
    label: "Комплект",
  },
  {
    id: "requisite2",
    label: "Реквизит",
  },
  {
    id: "commission",
    label: "Комис.",
  },
  {
    id: "author",
    label: "Автор",
  },
  {
    id: "cancel",
    label: "Отмена",
  },
  {
    id: "createdAt",
    label: "Дата",
  },
];

function Transfers() {
  const [page, setPage] = useState(0);
  const [rowsCount, setRowsCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  // Hooks
  useEffect(() => {
    HistoryService.transfersGet({
      page,
      rowsPerPage: rowsPerPage,
    }).then((r) => {
      let data = r.data;
      setRowsCount(data.count);
      setRows(data.rows);
    });
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Handlers
  const cancelTransfer = (id) => {
    if (!window.confirm("Отменить перевод?")) return;
    HistoryService.transfersCancel(id).then((r) => {
      setRows((list) => list.filter((h) => h._id !== id));
      toast.success("Перевод отменен");
    });
  };

  return (
    <Card>
      <CardHeader title="Внутренние переводы" />
      <TableContainer dense component={Paper} sx={{ maxHeight: "70vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              let takeRequisitId = row.take?.requisite?.id;
              let takeKitId = row.take?.kit?.id;

              let giveRequisitId = row.give?.requisite?.id;
              let giveKitId = row.give?.kit?.id;

              let possibleDelete =
                (takeRequisitId || takeKitId) && (giveRequisitId || giveKitId);

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                  <TableCell>
                    <PercentInfo percent={row.percent} />
                  </TableCell>
                  <TableCell>
                    <SumInfo sum={row.sum} />
                  </TableCell>
                  <TableCell>
                    <CurrencyName>{row.take.name}</CurrencyName>
                    <CurrencyPrice amount={row.take.amount} />
                  </TableCell>

                  <TableCell>
                    <ChangeBalance data={row.take.kit} />
                  </TableCell>
                  <TableCell>
                    {row.take.requisite && (
                      <ChangeBalance data={row.take.requisite} />
                    )}
                  </TableCell>
                  <TableCell>
                    <CurrencyName>{row.give.name}</CurrencyName>
                    <CurrencyPrice amount={row.give.amount} />
                  </TableCell>

                  <TableCell>
                    <ChangeBalance data={row.give.kit} />
                  </TableCell>
                  <TableCell>
                    {row.give.requisite && (
                      <ChangeBalance data={row.give.requisite} />
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {row?.сommission?.amount ? (
                        <>
                          <ChangeBalance
                            data={{
                              ...row.сommission.kit,
                              name: `${row.сommission.kit.name} (${row.сommission.name})`,
                            }}
                            code={row.сommission.code}
                          />
                          {/* <Typography>
                        {row.сommission.kit.name} - {row.сommission.name}
                      </Typography> */}
                        </>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{row.author}</Typography>
                  </TableCell>
                  <TableCell>
                    {possibleDelete && (
                      <IconButton
                        onClick={() => cancelTransfer(row._id)}
                        color="primary"
                        size="medium"
                      >
                        <Icon icon="icon-park-outline:return" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="secondary">
                      {moment(row.createdAt).format("DD.MM.YYYY, в HH:mm:ss")}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

function CurrencyName(props) {
  let { children } = props;

  return (
    <Typography
      sx={{ fontWeight: 600, fontSize: "14px" }}
      color="main"
      variant="body1"
    >
      {children}
    </Typography>
  );
}

function CurrencyPrice(props) {
  let { amount } = props;

  let [sum, code] = amount.split(" ");

  return (
    <Typography variant="body2">{`${new Intl.NumberFormat("ru-RU").format(
      sum
    )} ${code}`}</Typography>
  );
}

function PercentInfo(props) {
  let { percent } = props;

  let color = "";

  if (percent > 0) {
    color = "success.main";
  } else if (percent < 0) {
    color = "error.main";
  } else {
    color = "primary";
  }

  return (
    <Typography color={color} variant="body1">
      {`${percent > 0 ? "+" : ""}${percent} %`}
    </Typography>
  );
}

function SumInfo(props) {
  let { sum } = props;

  if (sum === undefined) return "-";

  let color = "";

  if (sum > 0) {
    color = "success.main";
  } else if (sum < 0) {
    color = "error.main";
  } else {
    color = "primary";
  }

  return (
    <Typography color={color} variant="body2">
      {`${sum}RUB`}
    </Typography>
  );
}

export default Transfers;
