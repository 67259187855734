import WEBSITES from "src/configs/websites";

const PER_PAGE_ORDERS = 24;

const WEBSITES_LIST = [
  {
    value: "all",
    name: "Все",
  },
  ...WEBSITES.map((w) => ({
    name: w.name,
    value: w.id,
  })),
];

const ORDER_STATUS_LIST = [
  {
    value: "all",
    name: "Все",
  },
  {
    value: "new",
    name: "Новые",
  },
  {
    value: "completed",
    name: "Выполненные",
  },
  {
    value: "rejected",
    name: "Отклоненные",
  },
  {
    value: "check",
    name: "На проверке",
  },
  {
    value: "archive",
    name: "Архив",
  },
  {
    value: "problem",
    name: "Проблемные",
  },
];

const ADVANCED_FILTER_STATUS_LIST = [
  {
    name: "Все",
    value: "",
  },
  {
    name: "Выполненные",
    value: "resolve",
  },
  {
    name: "Отклоненные",
    value: "reject",
  },
  {
    name: "В процессе",
    value: "procces",
  },
];

const INIT_ADVANCED_FILTER_DATA = {
  site: "",
  status: "",
  order: "",
  email: "",
  name: "",
  buy: "",
  sell: "",
  requisite: "",
  ip: "",
  comment: "",
  from: "",
  to: "",
};

export {
  WEBSITES_LIST,
  INIT_ADVANCED_FILTER_DATA,
  ORDER_STATUS_LIST,
  PER_PAGE_ORDERS,
  ADVANCED_FILTER_STATUS_LIST,
};
